.upload-library-container {
    padding-top: 76px;
    padding-left: 76px;
    padding-right: 15px;
    overflow-x: auto;

    .heading {
        font-size: 24px;
        color: #000000;
    }

    .notice {
        color: #434bdf;
        font-size: 11px;
        font-weight: 400;
        font-family: "Open Sans", sans-serif;
        margin-bottom: 0px;
    }

    .upload-image-container {
        margin-top: 59px;

        .choose-image-box {
            .upload-library-box {
                width: 250px;
                height: 230px;
                padding: 24px 16px 15px;

                .upload-library {
                    height: 125px;
                    width: 177px;
                    margin-bottom: 19px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .upload-image {
                    width: 52px;
                    height: 52px;
                    border: 2px solid #C0C0DC;
                    padding-top: 6px;
                    margin-top: 52px;
                    margin-bottom: 29px;

                    svg {
                        width: 34px;
                        height: 34px;
                        color: #C0C0DC;
                    }
                }

                .upload-new-btn {
                    .upload-new {
                        background-color: #ffffff;
                        color: #5959BE;
                        font-size: 11px;
                        display: block;
                        height: 32px;
                        font-weight: 500;
                        border-width: 1px;
                        border-style: solid;
                        border-color: #5959BE;
                        box-shadow: none;
                        text-transform: none;
                        padding-left: 5px;
                        padding-right: 5px;
                        width: 100px;

                        &:hover {
                            background-color: #ffffff;
                            color: #5959BE;
                        }
                    }
                }

                .upload-btn {
                    .upload {
                        background-color: #5959BE;
                        color: #ffffff;
                        font-size: 12px;
                        display: block;
                        height: 32px;
                        font-weight: 500;
                        border-color: #5959BE;
                        text-transform: none;

                        &:hover {
                            background-color: #6264A8;
                        }
                    }
                }
            }

            .choose-library-btn {
                margin-top: 15px;
            }
        }
    }

    .library-images {
        margin-top: 64px;

        .image-container {
            height: 200px;
            position: relative;
            padding-right: 17px;
            padding-bottom: 25px;

            .image {
                opacity: 0.7;
                cursor: pointer;
                height: 100%;
                width: 100%;
                background-color: #ffffff;
                text-align: center;

                img {
                    max-width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            .icon {
                position: absolute;
                right: 11px;
                top: -6px;
                background-color: #C9CADA;
                width: 16px;
                height: 16px;
                display: none;

                svg {
                    width: 12px;
                    height: 12px;
                    margin: 2px 0 0 2px;
                    display: inline-block;
                    vertical-align: top;
                }
            }

            &:hover,
            &.active {
                .image {
                    opacity: 1;
                }

                .icon {
                    display: block;
                }
            }
        }
    }
}