@import "fonts.scss";

html,
body,
#root {
  height: 100%;
  background-color: #fafafb;
}

body {
  font-family: "Open Sans", sans-serif;
}

* {
  &:focus {
    outline: none !important;
  }
}

.MuiInputBase-input {
  height: auto !important;
}

/************************** Scrollbar **********************************/
/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d8d9da;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c1c2c2;
}

/************************** Loader **********************************/

p.loading {
  color: white;
  font-size: 40px;
  text-align: center;
  margin-left: -20px;
}

/* loading dots */

.loading:after {
  content: " .";
  animation: dots 1s steps(5, end) infinite;
}

.loading.colored:after {
  content: " .";
  animation: colored 1s steps(5, end) infinite !important;
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}

@keyframes colored {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: #434bdf;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow: 0.25em 0 0 #434bdf, 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow: 0.25em 0 0 #434bdf, 0.5em 0 0 #434bdf;
  }
}

/************************** Animated buttons *******************/

.animate-on-hover {
  transition: width 0.5s, height 0.5s;
  svg {
    transition: margin-top 0.5s;
  }
}

/******************* Some misc ***************/

.clear-both {
  clear: both;
}

.MuiInputBase-root {
  &::before {
    border-color: #434bdf !important;
  }
  &::after {
    border-color: #434bdf !important;
  }
}

.create-survey-dialog {
  .MuiPaper-root {
    display: block;
    background-color: #fafafb;
    max-width: 680px !important;
    width: 680px !important;
    .MuiDialogContent-root {
      max-height: 400px;
    }
  }
  .heading {
    display: block;
    width: 100%;
    font-size: 24px;
    font-weight: 600;
    color: #434bdf;
    line-height: 30px;
    margin-bottom: 20px;
  }
  .survey-name {
    margin-bottom: 20px;
    input {
      height: 40px;
      resize: none;
      border: 1px solid #434bdf;
      color: #000000;
      font-size: 14px;
      font-weight: 500;
      width: 100%;
      border-radius: 4px;
      font-family: "Open Sans", sans-serif;
      padding: 5px 15px;
      &.error {
        border-color: #f44336;
      }
      &::placeholder {
        color: #000000;
        opacity: 0.6;
      }
    }
  }

  .survey-description {
    margin-bottom: 20px;
    textarea {
      height: 150px;
      resize: none;
      border: 1px solid #434bdf;
      color: #000000;
      font-size: 14px;
      font-weight: 500;
      width: 100%;
      border-radius: 4px;
      font-family: "Open Sans", sans-serif;
      padding: 15px;

      &::placeholder {
        color: #000000;
        opacity: 0.8;
      }
    }
  }
  .radio-btn {
  }

  .MuiDialogActions-root {
    padding: 20px 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    // .cancel {
    //   font-size: 15px;
    //   font-weight: 700;
    //   color: #434bdf;
    //   text-decoration: underline;
    //   text-transform: capitalize;
    //   padding: 0;
    //   margin-right: 28px;
    //   padding-top: 0px;

    //   &:hover {
    //     background-color: transparent;
    //   }
    // }
  }
}

.share-survey-dialog {
  .MuiPaper-root {
    display: block;
    background-color: #fafafb;
    padding: 30px;
    max-width: 680px !important;
    width: 680px !important;
    height: 190px;
    max-height: 190px;
  }
  .heading {
    display: block;
    width: 100%;
    font-size: 24px;
    font-weight: 600;
    color: #434bdf;
    line-height: 30px;
    margin-bottom: 20px;
  }

  .survey-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 15px;
    svg {
      cursor: pointer;
    }
  }

  .MuiDialogActions-root {
    display: block;
    width: 100%;
    padding: 0;

    .cancel {
      min-width: auto;
      font-size: 16px;
      font-weight: 700;
      color: #434bdf;
      text-transform: capitalize;
      padding: 0;

      &:hover {
        background-color: transparent;
      }
    }
  }
}

.theme-colors-popup {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  margin-bottom: 15px;
  .select-theme-color-btn {
    text-transform: capitalize;
    font-weight: 500;
    border-radius: 5px;
    width: 100%;
    height: 48px;
    text-align: left;
    justify-content: space-between;
    border: none;
    background-color: #5a5874;
    color: rgb(254, 254, 254);
    font-size: 15px;
    &:hover {
      background-color: #434bdf;
    }
  }
  .colors-popup {
    position: absolute;
    left: 0;
    top: 48px;
    width: 100%;
    padding: 0px;
    border-radius: 5px;
    border: 1px solid #434bdf;
    background-color: #ffffff;

    .heading {
      display: block;
      width: 100%;
      font-size: 14px;
      font-weight: 600;
      color: #434bdf;
      line-height: 18px;
      padding: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid #434bdf;
    }

    .colors-boxes {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-start;
      padding: 20px 5px 10px 5px;
      flex-wrap: wrap;
      button {
        width: 28px;
        height: 28px;
        margin: 0 5px 10px;
        cursor: pointer;
        border-radius: 5px;
        border: 1px solid #434bdf;

        &.active {
          position: relative;
          &::after {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            border-radius: 5px;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.8);
          }
        }
      }
    }

    .bottom-button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 10px;
      border-top: 1px solid #434bdf;

      .cancel {
        min-width: auto;
        font-size: 12px;
        font-weight: 500;
        color: #434bdf;
        text-transform: capitalize;
        padding: 0;

        &:hover {
          background-color: transparent;
        }
      }
      .apply {
        height: 24px;
        min-width: 80px;
        font-size: 12px;
        font-weight: 500;
        background-color: #5a5874;
        color: rgb(254, 254, 254);
        text-transform: capitalize;
        padding: 0;

        &:hover {
          background-color: #5a5874;
        }
      }
    }
  }
}

.survey-question-details-dialog {
  .MuiPaper-root {
    background-color: #fafafb;
    padding: 0;
    max-width: 680px !important;
    width: 900px !important;
    max-height: 800px;
    display: flex;
    align-items: stretch;
    h2 {
      display: block;
      width: 100%;
      font-size: 24px;
      font-weight: 600;
      color: #000000;
      line-height: 30px;
      margin-bottom: 20px;
    }
    .modal-body-content {
      .description-container {
        label {
          display: block;
          color: #000000;
          font-size: 14px;
          font-weight: 500;
          font-family: "Open Sans", sans-serif;
        }
        textarea {
          height: 150px;
          resize: none;
          border: 1px solid #434bdf;
          color: #000000;
          font-size: 14px;
          font-weight: 500;
          width: 100%;
          border-radius: 4px;
          font-family: "Open Sans", sans-serif;
          padding: 15px;
        }
      }
      .form-group {
        display: block;
        width: 100%;
        position: relative;

        label {
          display: block;
          color: #000000;
          font-size: 14px;
          font-weight: 500;
          font-family: "Open Sans", sans-serif;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .form-control {
          width: 100%;
          font-size: 14px;
          text-transform: capitalize;
          font-weight: 500;
          height: 40px;
          border-radius: 4px;
          color: #000000;
          font-weight: 400;
          margin-bottom: 5px;
          border-color: #434bdf;
          border: 0;
          padding: 10px 5px !important;
          .MuiOutlinedInput-notchedOutline {
            border-color: #434bdf !important;
          }

          .MuiInputBase-input {
            width: 100%;
            margin-left: -5px;
            margin-right: -5px;
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 10px;
            padding-bottom: 10px;
            line-height: 1.5;
            &.Mui-disabled {
              color: #000000;
              -webkit-text-fill-color: #000000;
            }
          }

          .MuiSvgIcon-root {
            color: #000000;
          }

          &.multiple-select {
            .MuiCheckbox-root {
              padding: 5px;
              display: inline-block;
            }

            .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
              height: 0;
              min-height: 32px;
              padding: 0;
              color: #000000;
            }
          }
        }
        .regex-text {
          position: absolute;
          top: 35px;
          right: 15px;
        }
      }
      .mandatory-switch {
        margin-left: 0;
        .MuiSwitch-track {
          background-color: #434bdf;
        }
        .Mui-checked {
          color: #434bdf;
          + .MuiSwitch-track {
            background-color: #434bdf;
            opacity: 0.5;
          }
        }
        .MuiTypography-root {
          display: block;
          color: #000000;
          font-size: 14px;
          font-weight: 500;
          font-family: "Open Sans", sans-serif;
        }
      }
    }
    .MuiDialogActions-root {
      display: block;
      width: 100%;
      padding: 20px 24px;
      text-align: end;
    }
  }
}
.form-group {
  display: block;
  width: 100%;
  position: relative;
  margin-bottom: 0;

  label {
    display: block;
    color: #434bdf;
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .eye-btn {
    position: absolute;
    top: 3px;
    right: 40px;
    color: #434bdf;
    padding: 5px;
  }
  .delete-btn {
    position: absolute;
    top: 3px;
    right: 5px;
    padding: 5px;
  }

 
  .Mui-disabled{
    opacity: 1;
    -webkit-text-fill-color: #000000!important;
   
  }

  .form-control {
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    height: 40px;
    border-radius: 4px;
    color: #434bdf;
    margin-bottom: 0;
    border-color: #434bdf;
    border: 0;
    padding: 10px 5px;
    .MuiOutlinedInput-notchedOutline {
      border-color: #434bdf !important;
    }

    .MuiInputBase-input {
      width: 100%;
      margin-left: -5px;
      margin-right: -5px;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
      line-height: 1.5;
    }

    .MuiSvgIcon-root {
      color: #434bdf;
    }

    &.multiple-select {
      .MuiCheckbox-root {
        padding: 5px;
        display: inline-block;
      }

      .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
        height: 0;
        min-height: 32px;
        padding: 0;
        color: #434bdf;
      }
    }
  }
  .regex-text {
    position: absolute;
    top: 35px;
    right: 15px;
  }
}

.common-divider {
  border-color: rgb(0 0 0 / 29%);
}

.common-form-check-box {
  padding: 5px;
}



.dark-outline-btn {
  text-transform: capitalize !important;
  color: #434bdf !important;
  border-color: #434bdf !important;
  span {
    .loadingIndicator {
      color: #fff !important;
    }
  }
}
.dark-contained-btn {
  text-transform: capitalize !important;
  background: #434bdf!important;
  color: #ffffff!important;
  span {
    span {
      .MuiCircularProgress-svg {
        .MuiCircularProgress-circle {
          stroke: white;
        }
      }
    }
  }
}
.disabled-btn {
  text-transform: capitalize !important;
  color: #9196f7!important;
  background-color: #d7d9ff!important;
}

.comparative-table {
  overflow-x: auto;
  width: 100%;

  .table {
    min-width: 950px;
    width: 100%;
    background-color: #ffffff;
    margin: 0;
    padding: 0px 5px;
    border: 1px solid #dee2e6;
    border-collapse: collapse;

    tr {
      display: flex;
    }

    th {
      border-top: none;
      padding: 10px 10px;
      vertical-align: middle;
      font-size: 16px;
      line-height: 18px;
      font-weight: 600;
      color: #434bdf;
      width: 100%;
      display: block;
      border-right: 1px solid #dee2e6;
      border-collapse: collapse;
      border-bottom: none;
      &:last-child {
        border-right: none;
      }
    }

    td {
      border-right: 1px solid #dee2e6;
      border-collapse: collapse;

      padding: 5px 5px;
      white-space: nowrap;
      width: 100%;
      display: block;
      &:last-child {
        border-right: none;
      }

      .radio-buttons {
        span {
          &::before {
            width: 27px;
            height: 27px;
            content: "";
          }
        }
      }

      .MuiIconButton-root {
        padding: 0;

        .MuiIconButton-label {
          span {
            width: 17px;
            height: 17px;

            &:before {
              width: 17px;
              height: 17px;
            }
          }

          input {
            width: 17px;
            height: 17px;
          }
        }
      }

      input {
        width: 100%;
        border: none;
        font-size: 16px;
        line-height: 18px;
        font-weight: 600;
        height: 100%;
        padding: 0;
        font-family: "Open Sans", sans-serif;
        text-align: center;
        background-color: transparent;
      }
    }
  }
}

.description-box {
  background: #ffffff;
  box-shadow: 0px 5px 10px #0000000d;
  border-radius: 2px;
  width: 100%;
  padding: 15px;
  min-height: 75px;

  h3 {
    color: #000000;
    font-size: 17px;
    line-height: 20px;
  }

  p {
    color: #000000;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 0;
  }
}

.preview-popup-container {
  .choice-question-container {
    h3 {
      margin-bottom: 10px;
    }
  }
}

h3 {
  display: block;
  color: #000000;
  font-size: 24px;
  line-height: 1.3;
  margin-bottom: 20px;
  font-weight: 600;
  + span {
    display: block;
    margin-bottom: 20px;
    font-size: 16px;
    color: #000000;
  }
}
.input-choice-container {
  .MuiInput-root {
    width: 100%;
  }
  .form-group {
    .question-text {
      height: 48px;
      cursor: pointer;
      border-color: #c8ccd0c2 !important;
      .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
        padding: 8px 20px;
      }
      &:hover {
        background-color: #f5f7f9;
      }
      &:focus-visible {
        border-color: #000000;
      }
      .MuiOutlinedInput-notchedOutline {
        border-color: #c8ccd0c2 !important;
      }
    }
  }
}
.checkbox {
  display: flex;
  width: 100%;
  border-radius: 3px;
  align-items: center;
  cursor: pointer;
  border-color: #c8ccd0c2 !important;
  margin-bottom: 10px !important;
  position: relative;
  .common-check-box {
    position: absolute;
    top: 4px;
    left: 0;
    z-index: 10;
    span {
      width: 22px !important;
      height: 22px !important;
      min-width: 22px !important;
      &::before {
        width: 22px !important;
        height: 22px !important;
      }
    }
  }

  .form-control {
    background: transparent;
    width: 100%;
    height: 48px;
    font-size: 16px;
    color: #000000;
    font-weight: 600;
    padding: 10px 10px 10px 45px;
    cursor: pointer;
    font-family: "Open Sans", sans-serif;
    .MuiOutlinedInput-notchedOutline {
      border-color: #c8ccd0c2 !important;
    }

    .MuiInputBase-input {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
      padding-bottom: 0;
      line-height: 1.5;
      cursor: pointer;
    }
  }

  &:hover {
    background-color: #f5f7f9;
  }
}
.radio {
  border: 1px solid #c8ccd0c2;
  height: 48px;
  border-radius: 3px;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px !important;
  position: relative;
  .radio-buttons {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 10;
  }
  .form-control {
    border: none;
    background: transparent;
    width: 100%;
    height: 48px;
    font-size: 16px;
    color: #000000;
    font-weight: 600;
    padding: 10px 10px 10px 45px;
    cursor: pointer;
    font-family: "Open Sans", sans-serif;
    .MuiOutlinedInput-notchedOutline {
      border-color: #c8ccd0c2 !important;
    }

    .MuiInputBase-input {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
      padding-bottom: 0;
      line-height: 1.5;
    }
  }
  &:hover {
    background-color: #f5f7f9;
  }
  .MuiIconButton-label {
    span {
      width: 17px;
      height: 17px;

      &:before {
        width: 17px;
        height: 17px;
      }
    }
  }

  input {
    cursor: pointer;
    border: none;
    background: transparent;
    padding-bottom: 3px;
    width: 90%;
    font-size: 16px;
    color: #434bdf;
    font-weight: 600;
    font-family: "Open Sans", sans-serif;
  }
}
.linescale-container {
  margin-top: 50px;
  margin-bottom: 60px;
  height: 27px;
  position: relative;
  background-color: rgba(191, 187, 226, 0.2);
  .weak-line-container {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0px;

    .weak-text {
      width: auto;
      font-size: 16px;
      line-height: 14px;
      color: #313348;
      margin-top: -32px;
      margin-bottom: 4px;
      margin-left: 0px;
      border: 1px solid #434bdf;
      background-color: rgba(191, 187, 226, 0.2);
      padding: 5px 10px;
    }

    .line {
      width: auto;
      text-align: left;
      height: 32px;

      span {
        height: 32px;
        width: 2px;
        content: "";
        display: inline-block;
        background-color: #6264a8;
      }
    }

    .number {
      text-align: left;
      width: auto;
      font-size: 14px;
      line-height: 18px;
      color: #000000;
    }
  }
  .strong-line-container {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0px;

    .strong-text {
      width: auto;
      font-size: 16px;
      line-height: 14px;
      color: #313348;
      margin-top: -32px;
      margin-bottom: 4px;
      margin-right: 0px;
      text-align: right;
      border: 1px solid #434bdf;
      background-color: rgba(191, 187, 226, 0.2);
      padding: 5px 10px;
    }

    .line {
      width: auto;
      text-align: right;
      height: 32px;

      span {
        height: 32px;
        width: 2px;
        content: "";
        display: inline-block;
        background-color: #6264a8;
      }
    }

    .number {
      text-align: right;
      width: auto;
      font-size: 14px;
      line-height: 18px;
      color: #000000;
    }
  }
}
.hedonic-container {
  .heading {
    padding-bottom: 5px;

    span {
      font-size: 10px;
      line-height: 12px;
      font-weight: 500;
      color: #2e2c2c;
      font-family: "Open Sans", sans-serif;
    }
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      text-align: center;
      font-size: 16px;
      color: #2e2c2c;
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
      line-height: 1.5;
      margin-right: 10px;
      margin-bottom: 10px;
      cursor: pointer;
      padding-bottom: 10px;
      padding-top: 10px;
      padding-left: 10px;
      padding-right: 10px;
      border-radius: 3px;
      list-style: none;
      border: 1px solid #c8ccd0c2;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.rating-question {
  .rating {
    margin-right: 10px;
    text-align: center;

    &:last-child {
      margin-right: 0;
    }

    .icon {
      width: 36px;
      height: 36px;

      &.click {
        color: #efa21c;
      }

      color: #dedede;

      svg {
        width: 36px;
        height: 36px;
      }
    }

    strong {
      display: block;
      color: #434bdf;
      font-size: 12px;
      line-height: 15px;
      font-weight: 500;
      font-family: "Open Sans", sans-serif;
    }
  }
  .rating-icon {
    span {
      display: inline;
      flex-wrap: wrap;
      label {
        margin-right: 15px;
        margin-bottom: 0;
        span {
          .MuiSvgIcon-root {
            width: 2em;
            height: 2em;
          }
        }
      }
      span {
        .MuiSvgIcon-root {
          width: 2em;
          height: 2em;
          color: #efa21c;
        }
      }
    }
  }
}
.jar-text {
  cursor: pointer;
  padding: 10px;
  text-align: center;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  color: #2e2c2c;
  line-height: 18px;
  font-weight: 600;
  border-radius: 3px;
  font-family: "Open Sans", sans-serif;
  border: 1px solid #c8ccd0c2;
}

@media only screen and (max-width: 767px) {
  .linescale-container {
    .MuiSlider-sizeSmall {
      padding: 0;
    }
  }
}
