.login-main-container {
  flex-wrap: wrap;
  background: rgb(255, 255, 255);
  height: 100%;
  overflow: hidden;

  .login-main-left {
    flex-wrap: wrap;
    .logo {
      margin-top: 0px;
      margin-bottom: 0px;
      align-self: self-start;
      justify-content: center;
      align-items: center;
      height: 18%;
    }
    .banner-img {
      height: 70%;
      padding-left: 60px;
      padding-right: 60px;
      align-items: center;
      text-align: center;
      justify-content: center;
      img {
        max-width: 100%;
        max-height: 100%;
        height: auto;
      }
    }
    .bottom-links {
      height: 12%;
      align-items: center;
      justify-content: space-between;
      padding: 0px 20px;
      align-self: self-end;
      .link-block {
        padding: 0;
        color: #fff;
        list-style: none;
        margin-bottom: 0;

        li {
          font-weight: normal;
          margin-right: 34px;
          font-size: 16.5px;

          a {
            color: #2f2e41;

            &:hover {
              color: #2f2e41;
              text-decoration: none;
            }
          }
        }
      }
      .copy-right-text {
        justify-content: flex-end;
        font-weight: normal;
        font-size: 16px;
        color: #2f2e41;
      }
    }
  }

  .login-header {
    height: 100px;
    align-self: flex-start;
    justify-content: center;
    align-items: center;
  }

  .login-component-container {
    height: 100%;
    align-items: center;
    background-color: #ffffff;
  }

  .footer-container {
    align-self: flex-end;
    align-items: center;
    height: 100px;
    background-color: #2f2e41;
  }
}

@keyframes navbarAnimation {
  from {
    right: -247px;
  }

  to {
    right: 0px;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
