.sidebar {
  width: 100%;
  display: flex;
  z-index: 9;
  position: fixed;
  flex: 0 0 100%;
  margin-top: 0px;
  padding-top: 0px;
  transition: all 0.2s;
  background-color: #fafafb;
  &.scrolled {
    box-shadow: 0px 3px 10px rgba(67, 75, 223, 0.1019607843);
  }
  .sidebar-menu {
    list-style: none;
    padding: 20px 20px;
    margin: 0px;

    li {
      display: inline-block;
      padding-right: 10px;
      a {
        align-items: center;
        color: #ffffff;
        background-color: #434bdf;
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 5px;
        font-family: "Open Sans", sans-serif;

        &:hover {
          background-color: #181e9b;
          color: #ffffff;
          text-decoration: none;
        }
      }

      &.active-page {
        a {
          background-color: #181e9b;
          text-decoration: none;
        }
      }
    }
  }
}

.djs-powered-by {
  display: none !important;
}
