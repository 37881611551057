.termsAndConditions-container {
    width: 100%;
    display: block;
    background: #fdfefe;
    padding-top: 60px;
    padding-bottom: 60px;
    .termsAndConditions-inner-container {
      width: 70%;
      margin: 20px auto 0;
      background: #ffffff;
      border-radius: 10px;
      padding: 25px;
      border: 1px solid gray;
      h2 {
        margin-bottom: 25;
      }
      h3 {
        margin-bottom: 20px;
      }
      p {
        font-size: 16px;
        line-height: 1.5;
        font-weight: 400;
        margin-bottom: 15px;
      }
      ul {
        padding-left: 50px;
        margin-bottom: 15px;
        li {
          font-size: 16px;
          line-height: 1.5;
          font-weight: 400;
          list-style: disc;
          a{
            color: #434bdf;
          }
        }
      }
      .terms-content {
        margin-bottom: 20px;
        label {
          font-size: 16px;
          line-height: 1.5;
          font-weight: 700;
        }
      }
    }
  }
  
  @media only screen and (max-width: 767px) {
    .termsAndConditions-container {
      width: 100%;
      display: block;
      .termsAndConditions-inner-container {
        width: 100%;
        margin: 0;
        background: #ffffff;
        border-radius: 0;
        padding: 25px;
        border: none;
        ul{
          padding-left: 30px;
        }
      }
    }
  }
  