.default-main-container {
  height: 100%;
  .main {
    height: 100%;
    padding-top: 60px;
    .content-container {
      width: 100%;
      height: 100%;
      padding-top: 20px;
      .content-inner-container {
        flex: 1 1;
        display: flex;
        z-index: 8;
        position: relative;
        height: 100%;
      }
    }
  }
}
