@font-face {
    font-family: "Arial MT Bold";
    src: local("Arial MT Bold"), url("../fonts/ArialRoundedMTBold.ttf");
}


@font-face {
    font-family: 'Avenir Heavy';
    src: url('../fonts/Avenir.eot');
    src: url('../fonts/Avenir.woff') format('woff'), 
         url('../fonts/Avenir.ttf') format('truetype'), 
         url('../fonts/Avenir.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Avenir LT Std 45 Book';
    src: url('../fonts/AvenirLTStd-Book.woff2') format('woff2'),
        url('../fonts/AvenirLTStd-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir LT Std 55 Roman';
    src: url('../fonts/AvenirLTStd-Roman.woff2') format('woff2'),
        url('../fonts/AvenirLTStd-Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir LT Std 65 Black';
    src: url('../fonts/AvenirLTStd-Black.woff2') format('woff2'),
        url('../fonts/AvenirLTStd-Black.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Medium';
    src: url('../fonts/AvenirMedium.woff2') format('woff2'),
        url('../fonts/AvenirMedium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

/************************ Roboto **************************************/

@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 400;
    src: url("../fonts/Roboto-Italic.ttf");
}

@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 500;
    src: url("../fonts/Roboto-MediumItalic.ttf");
}

@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 700;
    src: url("../fonts/Roboto-BoldItalic.ttf");
}

@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/Roboto-Regular.ttf");
}

@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    src: url("../fonts/Roboto-Medium.ttf");
}

@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/Roboto-Bold.ttf");
}

/************************** Open Sans *****************************/

@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    src: url("../fonts/OpenSans-Italic.ttf");
}

@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 600;
    src: url("../fonts/OpenSans-SemiBoldItalic.ttf");
}

@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 700;
    src: url("../fonts/OpenSans-BoldItalic.ttf");
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/OpenSans-Regular.ttf");
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url("../fonts/OpenSans-SemiBold.ttf");
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/OpenSans-Bold.ttf");
}