.header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 12;
  height: 60px;
  display: flex;
  align-items: center;
  align-content: center;
  transition: all 0.2s;
  background-color: #ffffff;
  // border-bottom: 1px solid #434bdf;
  box-shadow: 0px 3px 10px rgba(67, 75, 223, 0.1019607843);
  .profile-image {
    width: 38px;
    height: 38px;
    background: #434bdf;
    opacity: 1;

    span {
      color: #ffffff;
      font-size: 18px;
      line-height: 38px;
      margin-bottom: 0;
      font-weight: 700;
    }
  }
  .profile-dropdown {
    .dropdown-toggle {
      color: #000000;
      font-size: 16px;
      border: none;
      outline: none;
      position: relative;
      background: none;
      &:active {
        &:focus {
          box-shadow: none;
          border: none;
        }
      }

      &:focus {
        color: #000000;
        border: none;
        background-color: transparent;
        box-shadow: none;
      }
      &:after {
        display: none;
      }
    }
  }
  .dropdown-menu {
    width: 180px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    border: none;
    padding: 0;
    overflow: hidden;
    background-color: #434bdf;
    .dropdown-item {
      font-size: 14px;
      line-height: 22px;
      color: #ffffff;
      padding: 0.45rem 15px;
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
      border-bottom: 1px solid #181e9b;

      &:hover {
        background-color: #181e9b;
      }
      &:focus {
        background-color: #181e9b;
      }
    }
  }
}
