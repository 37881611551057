

.add-members-main {
  margin-top: 0px;
  height: 100vh;
  overflow: auto;
  overflow-y: auto;
  padding-bottom: 20px;
  align-items: center;
  

  

 

  .signin-form {
    .custom-input-container {
      margin-bottom: 15px;
      label {
        font-size: 15px;
        line-height: 1.5;
        font-weight: 600;
        color: #000000;
      }

      svg {
        color: #434bdf;
        width: 20px;
        height: 20px;
        z-index: 1;
        position: absolute;
        top: 48px;
        left: 12px;
      }

      .form-control {
        width: 412px;
        height: 52px;
        border: 1px solid #434bdf;
        background-color: #ffffff;
        border-radius: 7px;
        padding-left: 48px;
        font-size: 15px;
        line-height: 24px;
        // padding-top: 10px;
        color: #495057;
        box-sizing: border-box;

        &:focus {
          box-shadow: none;
          outline: none;
        }

        &::placeholder {
          color: rgba(46, 46, 85, 0.6);
        }

        &:-ms-input-placeholder {
          color: rgba(46, 46, 85, 0.6);
        }

        &::-ms-input-placeholder {
          color: rgba(46, 46, 85, 0.6);
        }
      }

      .invalid-feedback {
        width: 100%;
        margin-top: 0.25rem;
        font-size: 80%;
        color: red;
      }
    }
    .pasword-form {
      position: relative;
      .custom-input-container {
        .form-control {
          padding-right: 48px;
        }
      }
      .eye-icon {
        cursor: pointer;
        color: #434bdf;
        width: 20px;
        height: 20px;
        z-index: 1;
        position: absolute;
        top: 46px;
        right: 18px;
      }
    }

    .home-link {
      width: 100%;
      display: block;
      text-align: center;
      a {
        padding-top: 15px;
        color: #434bdf;
        font-size: 15px;
        font-weight: 500;
        line-height: 18px;
        display: inline-block;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}



@media only screen and (max-width: 767px) and (min-width: 320px) {
  .add-members-main {
    margin-top: 24px;
    margin-bottom: 84px;
    margin-left: auto;
    margin-right: auto;
    max-width: 94%;

    .signin-form {
      width: 100%;

      .custom-input-container {
        .form-control {
          width: 100%;
        }
      }

      .signup-button {
        width: 100% !important;
      }
    }
  }
}
